import React, { Component } from "react";
import {
    TopAppBar,
    TopAppBarFixedAdjust,
    TopAppBarRow,
    TopAppBarSection,
    TopAppBarTitle
} from "@rmwc/top-app-bar";
import Theme from "./Theme";
import {Snackbar} from "@rmwc/snackbar";
import '@rmwc/snackbar/styles';
import {Prompt} from "react-router-dom";

import "@rmwc/top-app-bar/styles"
import "@rmwc/tooltip/styles"
import "@rmwc/textfield/styles"
import "@rmwc/dialog/styles"
import "../App.sass"
import "@rmwc/data-table/styles"
import "@rmwc/circular-progress/styles"

class Layout extends Component {
    constructor(props) {
        super(props);

        this.beforeunload = this.beforeunload.bind(this);
    }

    async componentDidMount() {
        window.addEventListener('beforeunload', this.beforeunload);
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.beforeunload);
    }

    beforeunload(evt) {
        if (!this.props.canLeave) {
            evt.preventDefault();
            evt.returnValue = 'Es gibt ungespeicherte Änderungen, willst du wirklich verlassen?';
        }
    }

    render() {
        return (
            <Theme>
                <link rel="stylesheet" href={process.env.REACT_APP_API_DOMAIN + '/css/material/material-icons.css'} />
                <link rel="stylesheet" href={process.env.REACT_APP_API_DOMAIN + "/css/material/roboto_font.css"} />
                <TopAppBar fixed>
                    <TopAppBarRow>
                        <TopAppBarSection alignStart>
                            <TopAppBarTitle>{this.props.title || 'Formularbaukasten'}</TopAppBarTitle>
                        </TopAppBarSection>
                        {this.props.topBar || null}
                    </TopAppBarRow>
                </TopAppBar>
                <TopAppBarFixedAdjust />

                <>
                 <Snackbar
                        open={this.props.snackbar.open || false}
                        message={this.props.snackbar.message || null}
                        icon={this.props.snackbar.icon || "notifications_active"}
                        className={this.props.snackbar.error ? 'mdc-snackbar--error' : ''}
                        leading
                    >
                        {this.props.snackbar.action || null}
                    </Snackbar>
                    <Prompt
                        when={!this.props.canLeave}
                        message={"Es gibt ungespeicherte Änderungen, willst du wirklich verlassen?"}
                    />
                    {this.props.children}
                </>
            </Theme>
        );
    }
}

export default Layout;
