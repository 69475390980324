async function ajaxLogin(user, password) {
    const data = new FormData()
    data.append("user", user);
    data.append("password", password)

    const answer = await fetch(
        `${process.env.REACT_APP_API_DOMAIN}/login/login`,
        {
            method: "POST",
            mode: "cors",
            body: data,
        }
    )

    return await answer.json()
}

async function ajaxLogout() {
    const data = new FormData()

    const answer = await fetch(
        `${process.env.REACT_APP_API_DOMAIN}/login/logout`,
        {
            method: "POST",
            mode: "cors",
            body: data,
        }
    )

    return await answer.json()
}

async function ajaxCheckToken() {
    const data = new FormData()

    const answer = await fetch(
        `${process.env.REACT_APP_API_DOMAIN}/login/checkKey`,
        {
            method: "POST",
            mode: "cors",
            body: data,
        }
    )

    return await answer.json()
}

export {ajaxLogin, ajaxLogout, ajaxCheckToken}
