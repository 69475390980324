import React,{Component} from "react"

class EmptyState extends Component
{
    render() {
        return (
            <>
                <h2 style={{color: "var(--mdc-theme-text-primary-on-background)"}}>404 - Hier ist nichts!</h2>
                <div style={{color: "var(--mdc-theme-text-secondary-on-background)", textAlign: "center"}}>
                    Normalerweise solltest du hier über automatisch generierte Links hinkommen, dieser scheint aber
                    kaputt zu sein.
                    <br /><br />
                    Vielleicht hast du den Link nicht richtig kopiert? Oder die Dateien sind bereits abgelaufen.
                </div>
            </>
        )
    }
}

export default EmptyState
