async function ajaxListFiles(fileId) {
    const answer = await fetch(
        `${process.env.REACT_APP_API_DOMAIN}/getfile/listFiles?fileId=${fileId}`,
        {
            method: "GET",
            mode: "cors",
        }
    )

    return await answer.json()
}

async function ajaxGetFile(fileId, fileName) {
    const answer = await fetch(
        `${process.env.REACT_APP_API_DOMAIN}/getfile/getFile?fileId=${fileId}&fileName=${fileName}`,
        {
            method: "GET",
            mode: "cors",
        }
    )
    try {
        return await answer.json()
    } catch (e) {
        return {success: true};
    }

}

export {ajaxGetFile, ajaxListFiles}
