import React,{Component} from "react";
import Layout from "../Layout";
import {ajaxLogin, ajaxCheckToken, ajaxLogout} from "../../utils/LoginAjax";
import {ajaxListFiles, ajaxGetFile} from "../../utils/FileAjax";
import LoginDialog from "./LoginDialog"
import FileTable from "./FileTable";
import {TopAppBarSection, TopAppBarActionItem, TopAppBarTitle} from "@rmwc/top-app-bar";
import {Tooltip} from "@rmwc/tooltip";
import EmptyState from "../empty/EmptyState";
import DeletedState from "../empty/DeletedState";

class Files extends Component
{
    constructor(props) {
        super(props);

        this.state = {
            userName: "",
            files: [],
            deletedFiles: [],
            deletionDate: null,

            loginDialogOpen: false,

            downloading: null,
            loading: true,
        }

        this.downloadElementRef = React.createRef()

        this.handleLogin = this.handleLogin.bind(this)
    }

    async componentDidMount() {

        let answer = await ajaxCheckToken();

        if (answer.success) {
            this.setState({userName: answer.user})


            answer = await ajaxListFiles(this.props.match.params.fileId)

            if (answer.success) {
                this.setState({files: answer.files, deletedFiles: answer.deletedFiles, loading: false})

                return;
            }

            if (answer.deletionDate !== null && answer.deletionDate !== undefined) {
                this.setState({files: [], deletionDate: answer.deletionDate, loading: false})
                return;
            }

            this.props.openSnackbar(`Beim holen der Dateien ist ein Fehler aufgetreten: ` +
                `${answer.error || "Unbekannter Fehler"}. Informiere bitte einen Administrator, ` +
                `wenn dieser Fehler wieder auftritt`, "block", true)

            this.setState({loading: false})

            return;
        }

        this.setState({loginDialogOpen: true})
    }

    async handleLogin(userName, password, action) {
        this.setState({loginDialogOpen: false})

        if (action !== "login") {
            this.setState({loginDialogOpen: true})
            return;
        }

        let answer = await ajaxLogin(userName, password)

        if (answer.success) {
            this.setState({userName: userName})

            answer = await ajaxListFiles(this.props.match.params.fileId)

            if (answer.success) {
                this.setState({files: answer.files, loading: false})

                return true;
            }

            this.props.openSnackbar(`Beim holen der Dateien ist ein Fehler aufgetreten: ` +
                `${answer.error || "Unbekannter Fehler"}. Informiere bitte einen Administrator, ` +
                `wenn dieser Fehler wieder auftritt`, "block", true)

            return true;
        }

        this.props.openSnackbar(answer.error || "Beim einloggen ist ein Fehler aufgetreten", "block", true)

        this.setState({loginDialogOpen: true})

        return false;
    }

    async getFile(file)
    {
        const answer = await ajaxGetFile(this.props.match.params.fileId, file)

        if (answer.success) {
            // this.downloadElementRef.current.submit();
            window.open(`/getfile/getFile?fileId=${this.props.match.params.fileId}&fileName=${file}`, "_blank")
            this.setState({downloading: null})
            return;
        }

        this.setState({downloading: null})
        this.props.openSnackbar(`Beim herunterladen ist ein Fehler aufgetreten: ${answer.error}. Informiere ` +
                `bitte einen Administrator, wenn dieser Fehler wieder auftritt`, "block", true)
    }

    async logout()
    {
        const answer = await ajaxLogout()

        this.setState({
            files: [],
            userName: "",
            loginDialogOpen: true,
            loading: true
        })

        if (!answer.success) {
            this.props.openSnackbar(`Beim abmelden ist ein Fehler aufgetreten: ${answer.error || "Unbekannter Fehler"}.` +
                ` Informiere bitte einen Administrator, wenn dieser Fehler wieder auftritt`, "block", true)

            return;
        }
        this.props.openSnackbar("Erfolgreich abgemeldet", "check", false)
    }

    render() {
        const topBar = (
            <TopAppBarSection alignEnd>
                <TopAppBarTitle>
                    {this.state.userName ? `Eingeloggt als "${this.state.userName}"` : ""}
                </TopAppBarTitle>
                <Tooltip content={"Abmelden"}>
                    <TopAppBarActionItem icon={"exit_to_app"} onClick={() => this.logout()} />
                </Tooltip>
            </TopAppBarSection>
        )

        return (
            <Layout
                title={"Dateien herunterladen"}
                match={this.props.match}
                snackbar={this.props.snackbar}
                canLeave={true}
                topBar={topBar}
            >
                <div className={"download"}>
                    {
                        this.state.loading ? <div style={{color: "var(--mdc-theme-text-primary-on-background)"}}>Lade Dateien... Bitte warten</div> :
                            this.state.files.length !== 0 ?
                                <FileTable
                                    getFile={file => this.getFile(file)}
                                    files={this.state.files}
                                    downloading={this.state.downloading}
                                    deletedFiles={this.state.deletedFiles}
                                /> :
                                this.state.deletionDate === null ? <EmptyState /> :
                                    <DeletedState
                                        deletionDate={this.state.deletionDate}
                                    />
                    }
                </div>
                <LoginDialog open={this.state.loginDialogOpen} handleClose={this.handleLogin} />
            </Layout>
        )
    }
}

export default Files;
