import React,{Component} from "react"

class DeletedState extends Component
{
    render() {
        return (
            <>
                <h2 style={{color: "var(--mdc-theme-text-primary-on-background)"}}>404 - Dateien existieren nicht mehr!</h2>
                <div style={{color: "var(--mdc-theme-text-secondary-on-background)", textAlign: "center"}}>
                    Die angeforderten Daten wurden bereits am {this.props.deletionDate} gelöscht.
                    <br /><br />
                    Möglicherweise sind die Daten bereits veraltet.
                </div>
            </>
        )
    }
}

export default DeletedState
