import React,{Component} from "react"
import Layout from "../Layout";
import EmptyState from "./EmptyState";

class Empty extends Component
{
    render() {
        return (
            <Layout
                title={"Dateien herunterladen"}
                match={this.props.match}
                snackbar={this.props.snackbar}
                canLeave={true}
            >
                <div className={"download"}>
                    <h2 style={{color: "var(--mdc-theme-text-primary-on-background)"}}>404 - Hier ist nichts!</h2>
                    <div style={{color: "var(--mdc-theme-text-secondary-on-background)", textAlign: "center"}}>
                        Normalerweise solltest du hier über automatisch generierte Links hinkommen, dieser scheint aber
                        kaputt zu sein oder du bist manuel auf die Seite gegangen.
                        <br /><br />
                        Stelle sicher das der Link richtig kopiert wurde und die Dateien nicht abgelaufen sind
                    </div>
                </div>
            </Layout>
        )
    }
}

export default Empty
