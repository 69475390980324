import React,{Component} from "react";
import {DialogButton, DialogTitle, DialogActions, DialogContent, Dialog} from "@rmwc/dialog";
import {TextField} from "@rmwc/textfield";
import {validateEmailAddress} from "../../utils/validator";

class LoginDialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            userName: "",
            userNameInvalid: false,
            userNameText: "",
            password: "",
            passwordInvalid: false,
            passwordText: "",
            revealPasswords: false,
        }
    }

    close(action) {
        this.setState({
            revealPasswords: false
        })

        if (action === "close") {
            this.setState({
                userName: "",
                userNameInvalid: false,
                userNameText: "",
                password: "",
                passwordInvalid: false,
                passwordText: "",
            });

            if (
                this.state.passwordInvalid ||
                this.state.userNameInvalid ||
                this.state.password.length === 0 ||
                this.state.userName.length === 0
            ) {
                this.passwordChange(this.state.password)
                this.userNameChange(this.state.userName)
                return;
            }

        }

        if (this.props.handleClose(this.state.userName, this.state.password, action)) {
            this.setState({
                userName: "",
                userNameInvalid: false,
                userNameText: "",
                password: "",
                passwordInvalid: false,
                passwordText: "",
            });
        }

        this.setState({
            password: "",
            passwordInvalid: false,
            passwordText: "",
        })
    }

    passwordChange(password) {
        password = password.toString();

        this.setState({password, passwordInvalid: false, passwordText: ""})

        if (password.length === 0) {
            this.setState({
                passwordInvalid: true,
                passwordText: "Passwort darf nicht leer sein!"
            });
            return;
        }

        this.setState({
            passwordInvalid: false,
            passwordText: ""
        })
    }

    userNameChange(name) {
        name = name.toString();

        this.setState({userName: name, userNameInvalid: false, userNameText: ""})

        if (name.length === 0) {
            this.setState({
                userNameInvalid: true,
                userNameText: "E-Mail darf nicht leer sein!"
            });
            return;
        }

        if (!validateEmailAddress(name)) {
            this.setState({
                userNameInvalid: true,
                userNameText: "E-Mail ist nicht gültig!"
            });
            return;
        }

        this.setState({
            userNameInvalid: false,
            userNameText: ""
        })
    }

    render() {
        return (
            <Dialog
                preventOutsideDismiss={true}
                open={this.props.open}
                onClosed={(evt => this.close(evt.detail.action))}
            >
                <DialogTitle>Anmelden</DialogTitle>
                <DialogContent>
                    <TextField
                        style={{width: "260px"}}
                        label={"E-Mail"}
                        value={this.state.userName}
                        onChange={evt => this.userNameChange(evt.target.value)}
                        invalid={this.state.userNameInvalid}
                        helpText={{
                            persistent: true,
                            validationMsg: true,
                            children: this.state.userNameText
                        }}
                    />
                    <TextField
                        style={{width: "260px"}}
                        label={"Password"}
                        value={this.state.password}
                        onChange={evt => this.passwordChange(evt.target.value)}
                        invalid={this.state.passwordInvalid}
                        type={this.state.revealPasswords ? "text" : "password"}
                        trailingIcon={{
                            icon: this.state.revealPasswords ? "visibility_off" : "visibility",
                            tabIndex: 0,
                            onClick: () => this.setState({revealPasswords: !this.state.revealPasswords})
                        }}
                        helpText={{
                            persistent: true,
                            validationMsg: true,
                            children: this.state.passwordText
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <DialogButton action="login" disabled={this.state.passwordInvalid || this.state.userNameInvalid}>
                        Einloggen
                    </DialogButton>
                </DialogActions>
            </Dialog>
        )
    }
}

export default LoginDialog;
