import React,{Component} from "react";
import {DataTableCell, DataTable, DataTableBody, DataTableContent, DataTableHead, DataTableHeadCell, DataTableRow} from "@rmwc/data-table";
import {Button} from "@rmwc/button";
import {CircularProgress} from "@rmwc/circular-progress";

class FileTable extends Component
{
    render() {
        return (
            <div className={"download__table"}>
                <DataTable stickyRows={1}>
                    <DataTableContent>
                        <DataTableHead>
                            <DataTableRow>
                                <DataTableHeadCell>Name</DataTableHeadCell>
                                <DataTableHeadCell alignEnd>Aktion</DataTableHeadCell>
                            </DataTableRow>
                        </DataTableHead>
                        <DataTableBody>
                            {this.props.files.map(file => (
                                <DataTableRow key={file}>
                                    <DataTableCell>{file}</DataTableCell>
                                    <DataTableCell alignEnd>
                                        <Button
                                            icon={this.props.downloading === file ? <CircularProgress style={{color: "var(--mdc-theme-on-surface)"}} /> : "get_app"}
                                            label={"Herunterladen"}
                                            onClick={() => this.props.getFile(file)}
                                            raised
                                            disabled={this.props.downloading === file}
                                        />
                                    </DataTableCell>
                                </DataTableRow>
                            ))}
                            {this.props.deletedFiles.map(file => (
                                <DataTableRow key={file}>
                                    <DataTableCell>{file}</DataTableCell>
                                    <DataTableCell alignEnd>
                                        <Button
                                            label={"Gelöscht"}
                                            raised
                                            disabled={true}
                                        />
                                    </DataTableCell>
                                </DataTableRow>
                            ))}
                        </DataTableBody>
                    </DataTableContent>
                </DataTable>
                <div className={"download__table__text"}>
                    Hinweis: Der Browser zeigt beim ersten Herunterladen eine Meldung bezüglich Pop-Up's, es kann
                    sein, dass es dann einen Serverfehler beim Herunterladen gibt. Normalerweise sollte es funktionieren,
                    wenn man es einfach erneut probiert
                </div>
            </div>
        )
    }
}

export default FileTable;
