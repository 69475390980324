import React, {Component} from "react";
import Konami from "react-konami-code";
import {ThemeProvider} from "@rmwc/theme";

class Theme extends Component {
    constructor(props) {
        super(props);

        this.state = {
            theme: "dark"
        }
    }

    toggleDark() {
        if (this.state.theme === 'light') {
            this.setTheme('dark');
            return;
        }
        this.setTheme('light')
    }

    light = {
        primary: '#2962FF',
        secondary: '#388E3C',
        background: '#FFFFFF',
        surface: '#FAFAFA',
        error: '#C80000',
        onPrimary: '#FFFFFF',
        onSecondary: '#FFFFFF',
        onSurface: '#000000',
        onError: '#FFFFFF',

        textPrimaryOnBackground: 'rgba(0, 0, 0, 0.87)',
        textSecondaryOnBackground: 'rgba(0, 0, 0, 0.54)',
        textHintOnBackground: 'rgba(0, 0, 0, 0.38)',
        textDisabledOnBackground: 'rgba(0, 0, 0, 0.38)',
        textIconOnBackground: 'rgba(0, 0, 0, 0.38)',

        textPrimaryOnLight: 'rgba(0, 0, 0, 0.87)',
        textSecondaryOnLight: 'rgba(0, 0, 0, 0.54)',
        textHintOnLight: 'rgba(0, 0, 0, 0.38)',
        textDisabledOnLight: 'rgba(0, 0, 0, 0.38)',
        textIconOnLight: 'rgba(0, 0, 0, 0.38)',

        textPrimaryOnDark: '#FFFFFF',
        textSecondaryOnDark: 'rgba(255, 255, 255, 0.7)',
        textHintOnDark: 'rgba(255, 255, 255, 0.5)',
        textDisabledOnDark: 'rgba(255, 255, 255, 0.5)',
        textIconOnDark: 'rgba(255, 255, 255, 0.5)',

        border: 'rgba(0, 0, 0, .12)',
        chip: '#e0e0e0'
    };

    dark = {
        primary: '#2D89EF',
        secondary: '#388E3C',
        background: '#121212',
        surface: '#202020',
        error: '#c83042',

        onPrimary: '#000000',
        onSecondary: '#FFFFFF',
        onSurface: '#FFFFFF',
        onError: '#FFFFFF',

        textPrimaryOnBackground: 'rgba(255, 255, 255, 0.87)',
        textSecondaryOnBackground: 'rgba(255, 255, 255, 0.54)',
        textHintOnBackground: 'rgba(255, 255, 255, 0.38)',
        textDisabledOnBackground: 'rgba(255, 255, 255, 0.38)',
        textIconOnBackground: 'rgba(255, 255, 255, 0.38)',

        textPrimaryOnLight: 'rgba(255, 255, 255, 0.87)',
        textSecondaryOnLight: 'rgba(255, 255, 255, 0.54)',
        textHintOnLight: 'rgba(255, 255, 255, 0.38)',
        textDisabledOnLight: 'rgba(255, 255, 255, 0.38)',
        textIconOnLight: 'rgba(255, 255, 255, 0.38)',

        textPrimaryOnDark: '#000000',
        textSecondaryOnDark: 'rgba(0, 0, 0, 0.7)',
        textHintOnDark: 'rgba(0, 0, 0, 0.5)',
        textDisabledOnDark: 'rgba(0, 0, 0, 0.5)',
        textIconOnDark: 'rgba(0, 0, 0, 0.5)',

        border: 'rgba(255, 255, 255, .12)',
        chip: '#161616',
    };

    unicorn = {
        primary: '#FF4081',
        secondary: '#00E5FF',
        background: '#FFFFFF',
        surface: '#FFFFFF',
        error: '#C80000',
        onPrimary: '#FFFFFF',
        onSecondary: '#000000',
        onSurface: '#000000',
        onError: '#000000',

        textPrimaryOnBackground: 'rgba(0, 0, 0, 0.87)',
        textSecondaryOnBackground: 'rgba(0, 0, 0, 0.54)',
        textHintOnBackground: 'rgba(0, 0, 0, 0.38)',
        textDisabledOnBackground: 'rgba(0, 0, 0, 0.38)',
        textIconOnBackground: 'rgba(0, 0, 0, 0.38)',

        textPrimaryOnLight: 'rgba(0, 0, 0, 0.87)',
        textSecondaryOnLight: 'rgba(0, 0, 0, 0.54)',
        textHintOnLight: 'rgba(0, 0, 0, 0.38)',
        textDisabledOnLight: 'rgba(0, 0, 0, 0.38)',
        textIconOnLight: 'rgba(0, 0, 0, 0.38)',

        textPrimaryOnDark: '#FFFFFF',
        textSecondaryOnDark: 'rgba(255, 255, 255, 0.7)',
        textHintOnDark: 'rgba(255, 255, 255, 0.5)',
        textDisabledOnDark: 'rgba(255, 255, 255, 0.5)',
        textIconOnDark: 'rgba(255, 255, 255, 0.5)',

        border: 'rgba(0, 0, 0, .12)',
        chip: '#e0e0e0'
    };

    setTheme(theme) {
        localStorage.setItem('theme', theme);
        this.setState({theme})
    }

    render() {
        return (
            <ThemeProvider style={{background: 'var(--mdc-theme-background)', height: '100%', width: '100%'}} options={this[this.state.theme]}>
                <Konami action={() => this.setTheme('unicorn')}/>
                {this.props.children}
            </ThemeProvider>
        )
    }
}

export default Theme
