import {Route, Switch, useLocation} from "react-router-dom";
import React, {useState} from "react";
import Files from "./components/files/Files";
import Empty from "./components/empty/Empty";

const Router = () => {
    const [snackbar, setSnackbar] = useState({});

    const openSnackbar = (message, icon, error, action) => {
        setSnackbar({open: false})

        if (message === "SAVE_FAIL") {
            message = "Beim Speichern ist ein Fehler aufgetreten. Informiere bitte einen Administrator, wenn dieser Fehler wieder auftritt";
            icon = "warning";
            error = true;
        }

        window.requestAnimationFrame(() => {
            setSnackbar({open: true, message, icon, error, action})
        });
    }

    const location = useLocation();

    React.useEffect(() => {
        setSnackbar({})
    }, [location]);

    return (
        <Switch>
            <Route
                exact
                path="/files/:fileId"
                render={props => (
                    <Files {...props} snackbar={snackbar} openSnackbar={openSnackbar}/>
                )}
            />

            <Route
                render={props => (
                    <Empty
                        {...props}
                        snackbar={snackbar}
                        openSnackbar={openSnackbar}
                        urlValid={false}
                    />
                )}
            />
        </Switch>
    );
}

export default Router
